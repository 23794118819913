<template>
  <div>
    <h2 class="h6 m-0">{{$t('uvod-registrace')}}</h2>
    <div class="form-group">
      <div class="text-right">
        <a class="small" href="#" @click.prevent="$parent.box=''">{{$t('prihlas-se')}}</a>
      </div>
      <div class="form-group mb-0">
        <label for="inputName" class="sr-only">{{$t('reg.name')}}</label>
        <input
          type="text"
          v-model.trim="$v.name.$model"
          @input="delayTouch($v.name)"
          class="form-control form-control-sm"
          id="inputName"
          :placeholder="$t('reg.name')"
        />
        <small
          class="form-text text-muted invisible mt-0"
          v-if="!$v.name.$dirty || !$v.name.$invalid"
        >Vyplňte své jméno</small>
        <small
          class="form-text text-danger mt-0"
          v-if="$v.name.$dirty && !$v.name.required"
        >Toto pole je nutné vyplnit</small>

        <label for="inputEmail" class="sr-only">{{$t('reg.email')}}</label>
        <input
          type="email"
          v-model.trim="$v.email.$model"
          @input="delayTouch($v.email)"
          class="form-control form-control-sm"
          id="inputEmail"
          :placeholder="$t('reg.email')"
        />
        <small
          class="form-text text-muted invisible mt-0"
          v-if="!$v.email.$dirty || !$v.email.$invalid"
        >Vyplňte e-mailovou adresu</small>
        <small
          class="form-text text-danger mt-0"
          v-if="$v.email.$dirty && !$v.email.required"
        >Toto pole je nutné vyplnit</small>
        <small
          class="form-text text-danger mt-0"
          v-if="$v.email.$dirty && !$v.email.email"
        >Takto e-mailová adresa nevypadá</small>
        <!-- <small
          class="form-text text-danger"
          v-if="$v.email.$dirty && !$v.email.isUnique &&
          !$v.email.$pending && $v.email.email"
          >Tato e-mailová adresa je již zaregistrována</small>
          <small
            class="form-text text-secondary"
            v-if="$v.email.$dirty && $v.email.$pending && $v.email.email"
        >Ověřování jedinečnosti...</small>-->
      </div>

      <div class="form-group mb-0">
        <label for="inputPassword" class="sr-only">{{$t('reg.heslo')}}</label>
        <input
          type="password"
          v-model.trim="$v.password.$model"
          @input="delayTouch($v.password)"
          class="form-control form-control-sm"
          id="inputPassword"
          :placeholder="$t('reg.heslo')"
        />
        <small
          class="form-text text-muted invisible mt-0"
          v-if="!$v.password.$dirty || !$v.password.$invalid"
        >Zvolte si své nové heslo</small>
        <small
          class="form-text text-danger mt-0"
          v-if="$v.password.$dirty && !$v.password.required"
        >Toto pole je nutné vyplnit</small>
        <small
          class="form-text text-danger mt-0"
          v-if="$v.password.$dirty && !$v.password.minLength"
        >Heslo musí mít alespoň {{$v.password.$params.minLength.min}} znaků</small>
      </div>

      <div class="form-group mb-0">
        <label for="inputCompany" class="sr-only">{{$t('reg.companyname')}}</label>
       <!-- <input
          type="text"
          v-model.trim="company"
          class="form-control form-control-sm"
          id="inputCompany"
          :placeholder="$t('reg.companyname')"
        />-->
        <ejs-combobox id='inputCompany' ref='companyObj' class="form-control form-control-sm"
                      :popupHeight='height'
                      :filtering='onFilteringCompany'
                      :select="onCompanySelect"
                      :change="onCompanyChange"
                      :allowFiltering='true'
                      :fields='fields'
                      v-model="company_id"
                      :dataSource='data' :placeholder="$t('reg.companyname')"></ejs-combobox>


        <small class="form-text text-danger mt-0">&nbsp;<br></small>
      </div>

      <div class="form-group mb-0">
        <label for="inputBranch" class="sr-only">{{$t('reg.company.branch')}}</label>
        <ejs-dropdownlist ref='companyBranch'
                          id='inputBranch'
                          :select="onBranchSelect"
                          v-model="branch"
                          :dataSource='branchesData'
                          :fields="{text: 'name', value:'id'}"
                          :placeholder="'branch'" ></ejs-dropdownlist>
        <small class="form-text text-danger mt-0">&nbsp;<br></small>
      </div>


      <div class="form-group mb-0">
        <label for="inputCat" class="sr-only">{{$t('reg.company.category')}}</label>
        <ejs-dropdownlist :select='onCategorySelect' ref='companyCategry'
                          id='inputCat'
                          v-model="category"
                          :dataSource='categoryData'
                          :fields="{text: 'title', value:'id'}"
                          :placeholder="$t('reg.company.category')" ></ejs-dropdownlist>
        <small class="form-text text-danger mt-0">&nbsp;<br></small>
      </div>



      <div class="form-group mb-0">
        <label for="inputCountry" class="sr-only">{{$t('reg.stat')}} ...</label>

        <country-select v-model="country" :country="country" class="form-control countrysel"/>

     <small class="form-text text-danger mt-0">&nbsp;<br></small>
      </div>

      <div class="form-group mb-0">
        <label for="inputAddress" class="sr-only">{{$t('reg.adresa')}}</label>
        <input
          type="text"
          v-model.trim="$v.address.$model"
          @input="delayTouch($v.address)"
          class="form-control form-control-sm"
          id="inputAddress"
          :placeholder="$t('reg.adresa')"
        />
        <small
          class="form-text text-muted invisible mt-0"
          v-if="!$v.address.$dirty || !$v.address.$invalid"
        >Vyplňte město a ulici</small>
        <small
          class="form-text text-danger mt-0"
          v-if="$v.address.$dirty && !$v.address.required"
        >Toto pole je nutné vyplnit</small>
      </div>

      <div class="form-group mb-0">
        <label for="inputPhone" class="sr-only">{{$t('reg.telefon')}}</label>
        <input
          type="text"
          v-model.trim="$v.phone.$model"
          @input="delayTouch($v.phone)"
          class="form-control form-control-sm"
          id="inputPhone"
          :placeholder="$t('reg.telefon')"
        />
        <small
          class="form-text text-muted invisible mt-0"
          v-if="!$v.phone.$dirty || !$v.phone.$invalid"
        >Vyplňte telefon</small>
        <small
          class="form-text text-danger mt-0"
          v-if="$v.phone.$dirty && !$v.phone.required"
        >Toto pole je nutné vyplnit</small>
      </div>

      <div class="form-check">
        <input
          type="checkbox"
          v-model.trim="$v.agree.$model"
          class="form-check-input"
          id="exampleCheck1"
        />
        <label
          class="small form-check-label"
          for="exampleCheck1"
        >{{ $t('reg.agree-principles')}}</label>
        <small
          class="form-text text-muted invisible mt-0"
          v-if="!$v.agree.$dirty || !$v.agree.$invalid"
        >Potvrďte souhlas</small>
        <small
          class="form-text text-danger mt-0"
          v-if="$v.agree.$dirty && !$v.agree.checked"
        >Je zapotřebí Váš souhlas</small>
      </div>



      <button
        @click="registruj($v)"
        class="btn btn-sm btn-secondary w-100 my-2"
      >{{ $t('reg.registrovat')}}</button>

      <div class="pt-2" v-if="msg.length > 0 || errors!=null">
        <i class="fas fa-info-circle mr-2"></i>
        {{msg}}
        <div v-if="errors!=null">
          <p :key="k" v-for="(e,k) in errors">{{k}} {{e}}</p>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
const qs = require("qs");
const axios = require("axios");

import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
const touchMap = new WeakMap();
import { ComboBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";
import Vue from "vue";
import { Query } from "@syncfusion/ej2-data";
Vue.use(ComboBoxPlugin);
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
Vue.use( DropDownListPlugin);

import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)

export default {
  name: "Registrace",
  components: {  },
  data() {
    return {region: '',

      email: "",
      password: "",
      country: "",
      address: "",
      company:'',
      phone: "",
      name: "",
      agree: false,
      msg: "",
      errors: null,
      company_id:'',
      branch_id: null,


      categoryData:[],
      branchesData:[],
      branch:{},
      category:null,
      data: [],
      fields: {text: 'name', value:'id'},
      height: '330px',
      waterMark: 'Select a country',


      query :  new Query().from('companies').select(['name', 'id']).take(6),

    };
  },
  mounted(){
    //console.log("mounted reg");
    axios.get(   process.env.VUE_APP_API_ROOT_URL + "/companies/categories", {params:{lang: this.$i18n.locale}}).then(result => {
      //console.log('reg', result.status, result.data)
      if (result.status == 200) {
        // frame the query based on search string with filter type.
        this.categoryData = result.data.categories
        for (var i=0 ; i< this.categoryData.length; i++){
          if (!this.categoryData[i].title || this.categoryData[i].title==""){
            this.categoryData[i].title = this.$t(this.categoryData[i].key)
          }
        }
      }
    })
    //categoryData
  },
  validations: {
    email: {
      required,
      email
      // isUnique(value) {
      //     // pro validaci musi byt platny email format
      //     if (!this.$v.reg.mail.email) return true

      //     return new Promise((resolve, reject) => {
      //         axios.get("/profil/check-email", {params:{email: value }}).then(response => {
      //             if (response.data.unique == 1){
      //                 //console.log('povoleni')
      //                 resolve(true)
      //             }else{
      //                 reject(false)
      //             }

      //         }).catch((error) => {
      //             reject(false)
      //         });
      //     })
      // }
    },
    password: {
      required,
      minLength: minLength(6)
    },
    // repeatPassword: {
    //   required,
    //   sameAsPassword: sameAs("password")
    // },
    country: {
      required
    },
    address: {
      required
    },
    name: {
      required
    },
    phone: {
      required
    },
    agree: {
      checked: sameAs(() => true)
    },
    company:{

    },
    register: [
      "email",
      "name",
      "password",
      "agree",
      "country",
      "address",
      "phone",
      "agree",
            "company"
    ]
  },
  methods: {

    onFilteringCompany: function(e) {
      //console.log('filtering, ', e.text)
      var t= e.text
      this.company = e.text
      axios.get(   process.env.VUE_APP_API_ROOT_URL + `/companies/index`, {params: {q: e.text}}).then(result => {
        //console.log('reg', result.status, result.data)
          if (result.status == 200) {
             // frame the query based on search string with filter type.
            let data = result.data.companies
            //console.log('data,', data)
            e.updateData(data)
            e.text = t
          }
      })
    },

    onCompanyChange(e){
      //console.log("Company ch", e.itemData)
      this.branch_id = null
      var id = parseInt(e.itemData.id)
      this.company = e.itemData.name
      if (e.itemData.country) this.country = e.itemData.country.toUpperCase(); else this.country=''
      this.address = e.itemData.address
      if ( !isNaN(id) && id>0){
        if (e.itemData.category_id){
          this.category = e.itemData.category_id
        }
        this.reloadBranches(id)
      }
    },

    onCompanySelect(e){
      console.log("CE se", e.itemData.id)
    },

    onCategorySelect(){
      //console.log('cat sel', this.category)
    },

    onBranchSelect(e){
      console.log("branch sel ", e.itemData.id)
      this.branch_id = e.itemData.id
    },

    reloadBranches(id){

      axios.get(process.env.VUE_APP_API_ROOT_URL + "/accounts",
          {params: {company_id: id }}
      ).then(result => {
        //console.log('reg', result.status, result.data)
        if (result.status == 200) {
          this.branchesData = result.data
        }
      })

    },

    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
    registruj($v) {
      $v.register.$touch();
      if (!$v.register.$invalid) {
        axios
          .post(
            process.env.VUE_APP_API_ROOT_URL + "/user",
            qs.stringify({
              email: this.email,
              password: this.password,
              tel: this.phone,
              country: this.country,
              address: this.address,
              name: this.name,
              company_name: this.company,
              regtoken: this.$store.getters.regtoken,
              source: 'web',
              category: this.category,
              company_id: this.company_id,
              locale: this.$i18n.locale,
              branch_id: this.branch_id
            })
          )
          .then(result => {
            //console.log('reg', result.status, result.data)
            if (result.status == 200 && result.data.id > 0) {
              this.msg = "Registrace proběhla v pořádku.";

              this.$store.commit("setuser", result.data);

              localStorage.setItem('user-token', result.data.user_token)
              axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.user_token}`

              console.log('USSSUUSUS',  result.data.user_token, result.data )

              this.$router.push({ path: "/main/motors" });
            } else {
              this.msg = "Chyba";
              this.errors = result.data.errors;
            }
          })
          .catch(err => {
            console.error("chyba",err.response.status,err.response.data.errors);

           this.errors = err.response.data.errors;


          });
      }
    }
  }
};
</script>
<style>


@import "../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css";
@import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/bootstrap4.css";

.form-control.countrysel{
  color: #6c757d;
  font-size:90%;
  height:32px;
  padding:4px;
  font-size:1em
}

</style>
