import {mapState} from "vuex";
import moment from "moment";
require('moment/locale/cs');
moment.locale('cs');
const axios = require("axios");
axios.defaults.withCredentials = true;
export default {
    computed: {
// ...mapState('auth', ['loggedIn', 'user']),
        ...mapState({ me2: state => state.me }),

        token(){
            return localStorage.getItem('user-token') || ''
        }

    },
    methods:{
        logout(){
            axios.get(process.env.VUE_APP_API_ROOT_URL + "/logout?t="+ new Date().getTime()).then(result => {
                if (result.status == 200) {
                    this.$store.commit('setuser', false)
                    this.$router.push('/')
                    localStorage.setItem('user-token', null)
                    axios.defaults.headers.common['Authorization'] = ''
                }
            })
        },
    },

    filters: {

        date(value) {
            value = moment(value).format('DD. MM. YYYY')
            return value
        },

        dateTime(value) {
            if (value==null) return ""
            value = moment(value).format('DD. MM. YYYY, H:mm')
            return value
        },

        fromNow(value){
            return  moment(value).fromNow();
        },
        defaultAvatar(url){
            return url ? url : '/Login.png'
        }


    },
}
