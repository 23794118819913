<template>
  <div id="app">
    <nav id="nav" class="navbar navbar-expand-sm py-1"  v-if="loaded && zobrazovatNav" >
      <div class="mr-auto">
        <div class="blokprologo"><img src="/beedroid.svg" class="logo cursor-pointer" @click="clickLogo"></div>
        <a class="btn" style="width:100px;"></a>

        <!-- <router-link to="/" class="btn btn-sm mx-2" >{{$t('Home')}}</router-link>
-->
        <span v-if="me && me.id>0  && zobrazovatNav" class="nav-items">
          <router-link to="/home" class="btn btn-sm mx-2">{{$t('menu.home')}}</router-link>
          <router-link to="/main/motors" :class="'btn btn-sm mx-2 '+(podstrankyMotoru ? 'router-link-exact-active router-link-active':'')">{{$t('page.seznam-motoru')}}</router-link>
          <router-link to="/community" class="btn btn-sm mx-2">{{$t('menu.community')}}</router-link>
          <router-link to="/qr-generator" class="btn btn-sm mx-2">{{$t('menu.qr-generator')}}</router-link>
          <router-link to="/admin/interval-settings" class="btn btn-sm mx-2" v-if="me && me.role>10">{{$t('menu.administrace')}}</router-link>
          <router-link to="/manufacture" class="btn btn-sm mx-2">{{$t('menu.manufacture')}}</router-link>

        </span>


      </div>
  <!--    <button class="btn btn-sm mr-2" ><i class="fas fa-bars"></i></button>
      <button class="btn btn-sm" ><i class="fas fa-search"></i></button> -->

      <div class="btn-group mr-4">

        <span v-click-outside="hide" @click="toggle" @click.prevent="showNotices()"
              class="btn btn-sm mr-3 notice-button" :title="$t('menu.notice') "><i class="far fa-bell"></i><notice ref='noticebox'></notice></span>

        <button class="btn btn-outline-dark btn-sm dropdown-toggle " type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{this.$i18n.locale}}
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" v-for="(lang, i) in langs" :key="`Lang${i}`" @click.prevent="setLocale(lang)" href="#">{{ lang }}</a>
        </div>
      </div>

      <router-link  to="/help" class="btn btn-sm" :title="$t('help.menu')"><i class="fas fa-info-circle"></i></router-link>


      <router-link v-if="me && me.id>0"  to="/profil" class="btn btn-sm mx-2" :title="$t('menu.nastaveni-moje-udaje')"><i class="fas fa-cog"></i></router-link>
      <a v-if="me && me.id>0" href="/" class="btn btn-sm mx-2" @click.prevent="logout()" :title="$t('menu.logout')">  <i class="fas fa-sign-out-alt"></i></a>
    </nav>
  <!--  <div v-else class="nahradni-nav">
      <div class="btn-group mr-0 " v-if="loaded">
        <button class="btn btn-outline-dark btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{this.$i18n.locale}}
        </button>
        <div class="dropdown-menu" style="min-width: 20px;">
          <a class="dropdown-item" v-for="(lang, i) in langs" :key="`Lang${i}`" @click.prevent="setLocale(lang)" href="#">{{ lang }}</a>
        </div>
      </div>
    </div>-->



    <router-view v-if="loaded"/>
  </div>
</template>

<script>
import Notice from "@/views/Notice";
const axios = require("axios");
axios.defaults.withCredentials = true;

import ClickOutside from 'vue-click-outside'
import userMixin from "@/mixins/userMixin";


export default {
  directives: {
    ClickOutside
  },
  components: {Notice},
  mixins:[userMixin],
  computed:{

    podstrankyMotoru(){
      return  this.$route.path.indexOf('/motors/')>=0 || this.$route.path.indexOf('/team/')>=0 || this.$route.path.indexOf('/groups')>=0


    },

    ukazatPrepinacLang(){
      if (window.location.href.indexOf('/regc/')>=0) return true
      if (window.location.href.indexOf('/terms')>=0) return true

      return false
    },

    zobrazovatNav(){
       var k  = window.location.href.indexOf('/regc/')

      if (window.location.href.indexOf('/terms')>0 || window.location.href.indexOf('/community2')>0) return false

       return  this.$route.name!='qr-print' && this.$route.name!='qr-print2'  && k<0
    },

    me(){
      return this.$store.getters.me
    }
  },
  mounted() {
    var that = this
    var config ={
    }
    if (window.location.href.indexOf('/community/')>=0 ){
        let ass = window.location.href.split('/community/')
        console.log(ass[1])
      if (ass[1]){
        config.headers= {
          'Authorization': `Bearer ${ass[1]}`
        }
        localStorage.setItem('user-token', ass[1])
        axios.defaults.headers.common['Authorization'] = `Bearer ${ass[1]}`
      }

    }
    axios.get(process.env.VUE_APP_API_ROOT_URL + "/user/me.json?f=3&t="+ new Date().getTime(), config).then(result => {
      //console.log('me', result.status, result.data)

      var path = location.pathname.substring(1);
      if (result.status == 200 && result.data.id > 0) {
        localStorage.uid = result.data.id
        this.$store.commit('setuser', result.data)
        that.$emit('me', result.data.data)
        that.$nextTick(()=>{
          this.$i18n.locale = result.data.locale
        })
        localStorage.locale = result.data.locale
      }
      //console.log('pppa', path)
      if ((!path || path=='') && result.data.id > 0) {
        window.location.href = "/home"
      }else{
        this.loaded=true
      }

      if (this.$refs.noticebox){
        this.$refs.noticebox.refreshData()
      }

    }).catch((err) =>{
      console.log('catch mee', err )
      this.loaded=true
    })
  },
  data () {
    return {
      langs: ['cs', 'en', 'zh', 'ru'],
      loaded: false,
      notice_visible: false

    }

  },
  methods:{

    clickLogo(){
      if (this.me) {
        this.$router.push('/home')

      }else{
        this.$router.push('/')
      }
    },

    toggle () {
      //this.notice_visible = true
     // console.log('faaa toggle')
      //this.notice_visible = true
    },

    hide () {
   //   console.log('faaa hide')
      //this.notice_visible = false
      this.$refs.noticebox.hide()
    },



    setLocale(lang){
      //console.log('l lang', lang)
      this.$i18n.locale = lang
    },

    showNotices(){
      this.$refs.noticebox.toggle()
      console.log('on notic togle')
      this.$refs.noticebox.refreshDataifEmpty()
    }
  },


}
</script>
