import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)
const axios = require("axios");
axios.defaults.withCredentials = true

//import user from "./modules/user";
//import auth from "./modules/auth";

export const store = new Vuex.Store({
    modules: {
  //      user,
  //      auth
    },

    me:{id:  -1},

    state: {
        count: 111,
        me: {id:''},
        regtoken: null,
        motor:null
    },

    mutations: {
        incrementCounter (state, payload) {
            state.count += payload
        },

        setuser(state, payload) {
            state.me = payload
        },
        setRegToken(state, payload) {
            state.regtoken = payload
        },

        setMotor(state, payload) {
            state.motor = payload
        },

    },

    actions: {
        inrementAction ({commit}, payload) {
            commit('incrementCounter', payload)
        }
    },

    getters: {
        motor(state){
            return state.motor
        },
        regtoken(state){
            return state.regtoken
        },
        counter (state) {
            return state.count
        },
        me(state){

            if (state.me === null ){

                try {
                    axios.get(process.env.VUE_APP_API_ROOT_URL + "/user/me.json?t="+ new Date().getTime()).then(result => {
                        //console.log('me', result.status, result.data)
                        if (result.status == 200 && result.data.id > 0) {

                            //console.log('meme', result.data.id, window.location.href)
                            var h = window.location.href
                            if ( h==='http://localhost:8080/' || h==='https://www.bee40.com/') window.location.href = '/main/motors'

                            store.commit('setuser', result.data)
                        }
                    })
                        .catch(err => {
                            if (window.location.href.indexOf("/terms")>=0) return

                            store.commit('setuser', {id:  -1})
                            if (err.response.status==401){
                                var j = window.location.href.indexOf("/regc/");

                                console.log('unahthorised1', window.location.href, j)
                                //if (j<0 && window.location.href!='http://localhost:8080/' && window.location.href!='https://smartmotor.s8.pkis.cz/') window.location.href = "/"
                            }
                            return null
                        });
                }catch (err) {
                    console.error(" me eror", err)
                    store.commit('setuser', {id:  -1})
                    return null
                }
            }
            return state.me
        }
    }

})
