import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.scss';
import './styles/small.scss';

import i18n from './i18n'
import Vuex from 'vuex'

import { store } from './store'
Vue.use(Vuex)
Vue.config.productionTip = false

import vmodal from 'vue-js-modal'
Vue.use(vmodal)

import Nl2br from 'vue-nl2br'

Vue.component('nl2br', Nl2br)

import Toasted from 'vue-toasted';
const axios = require("axios");
Vue.use(Toasted)

const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
