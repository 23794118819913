<template>
    <div>
        <h2 class="h6 m-0">{{$t('zapomenute-heslo')}}</h2>
      <div class="text-right my-3">
        <a class="small" href="#" @click.prevent="$parent.box=''">{{$t('prihlas-se')}}</a>
      </div>
        <div class="form-group" v-if="!odeslano">

            <div class="form-group mb-2">
                <label for="inputEmail" class="sr-only">{{$t('reg.email')}}</label>
                <input
                        type="email"
                        v-model.trim="email"

                        class="form-control form-control-sm"
                        id="inputEmail"
                        :placeholder="$t('reg.email')"
                />
            </div>
            <button
                    @click="resetPassReq()"
                    class="btn btn-sm btn-secondary w-100 my-2"
            >{{ $t('button.obnovit-heslo')}}</button>




        </div>


      <p v-if="odeslano" class="mt-3">{{ $t('obnova-hesla-text') }}</p>
      <div @click="konecRelace"  v-if="msg.length > 0" class="mt-3 alert alert-success"><i class="fas fa-info-circle mr-2"></i>{{msg}}</div>
      <p v-if="errorMessage.length > 0" class="mt-3"><i class="fas fa-exclamation-triangle text-danger mr-2"></i>{{errorMessage}}</p>

    </div>
</template>

<script>
  const qs = require("qs");
  const axios = require("axios");
  export default {
    name: "ResetPassword",
    data(){
      return {
        odeslano:false,
        email:'',
        msg: 'ok',
        errorMessage:''
      }
    },
    methods:{
      konecRelace(){
        this.$parent.box=''
      },


      resetPassReq(){
        this.msg=''
        this.errorMessage=''
        axios
          .post(
            process.env.VUE_APP_API_ROOT_URL + "/user/reset-req",
            qs.stringify({ email: this.email })
          )
          .then(result => {
            if (result.status == 200 || result.status == 204) {
                this.msg = 'ok'
                this.errorMessage = ''
                this.odeslano = true
            }
          })
          .catch(err => {
            this.errorMessage = err.response.data.message;
          });
      }
    }
  }
</script>

<style scoped>

</style>
