<template>
  <div class="container-fluid" id="main1">
    <!-- v-if="me && me.id>0"> -->
    <div class="row main-content">
      <div class="col sidebar">
        <div class="text-left " style="margin-top:90px;">


        </div>
      </div>

      <div class="col content">
        <div class="row h-100 pb-2 mb-2">
          <div class="col px-3 px-sm-4">
           <!-- <router-view></router-view> -->
            <div class="row mt-5">
              <div class="col-md-6">
                    <div class="uvod-digital-1">{{$t('uvod-digital-1')}}</div>
                    <div class="uvod-digital-2">{{$t('uvod-digital-2')}}</div>
              </div>
              <div class="col-md-5 login-form" v-if="box=='reg' || box=='reset'">
                <div class="row h-100 justify-content-center" v-if="box=='reg'" >
                  <div
                      class="col mt-5 px-3 px-sm-5"
                      v-if="me && me.id>0"
                  >Přihlášen: {{ me.email }}<br> {{ me.name }}<br>
                    <a href="#" @click.prevent="logout()">odhlasit</a>
                  </div>

                  <div class="col mt-5 px-3 px-sm-5" >
                    <Registrace></Registrace>
                  </div>


                </div>

                <div class="row h-100 justify-content-center">
                  <div class="col mt-5 px-3 px-sm-5" v-if="box=='reset'">
                    <ResetPassword></ResetPassword>
                  </div>
                </div>


              </div>

            </div>

            <div class="row">
              <div v-if="!me || me.id==undefined || me.id==''" style="width:500px;">
                <div class="col mt-5 px-3 px-sm-5 box-prihlaseni" v-if="box==''">
                  <h2 class="h3 m-0 mb-3 pt-4">{{$t('uvod-prihlaseni')}}</h2>
                  <div class="form-group">

                    <input
                        type="email"
                        class="form-control form-control-sm mb-3"
                        placeholder="Email"
                        v-model="email"
                    />
                    <input
                        type="password"
                        class="form-control form-control-sm mb-1"
                        v-model="password"
                        :placeholder="$t('Heslo')"
                    />
                    <div class="mb-3">
                      <a class="small" href="#" @click.prevent="box='reset'">{{$t('zapomenute-heslo')}}</a>
                    </div>
                    <button @click="login" class="btn btn-sm btn-secondary w-100">{{ $t('vstoupit')}}</button>
                    <div class="form-check form-control-sm">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      <label class="form-check-label" for="exampleCheck1">{{$t('home.stay-logged')}}</label>
                    </div>
                  </div>
                  <div class="pt-2" v-if="errorMessage.length > 0">
                    <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
                    {{errorMessage}}
                  </div>

                  <div class="text-center pb-3">
                    <a class="small" href="#" @click.prevent="box='reg'">{{$t('zaloz-ucet')}}</a>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import Registrace from "../components/Registrace";
import ResetPassword from "@/components/ResetPassword.vue";
import {AUTH_REQUEST, USER_REQUEST} from "@/store/mutation-types";

const qs = require("qs");
const axios = require("axios");
axios.defaults.withCredentials = true;

export default {
  name: "home",
  components: {
    Registrace, ResetPassword
    // HelloWorld
  },
  data() {
    return {
      email: "",
      password: "",
      msg: "",
      errorMessage: "",
      box:''
    };
  },
  mounted() {
    //console.log("mounted home", this.$store.getters.me);

    console.log('reg r', this.$store.getters.regtoken )
    if (this.$route.query.reg === null){
      this.box='reg'
    }
  },
  computed: {
    me() {
      return this.$store.getters.me;
    }
  },
  methods: {
    login() {
      axios
        .post(
          process.env.VUE_APP_API_ROOT_URL + "/login",
          qs.stringify({ email: this.email, password: this.password })
        )
        .then(result => {
          //console.log("reg", result.status, result.data);
          if (result.status == 200) {
            this.$store.commit('setuser', result.data)
            this.errorMessage = "OK";
            localStorage.setItem('user-token', result.data.user_token)
            axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.user_token}`
            this.$router.push({ path: '/home' })

          }
        })
        .catch(err => {
          console.log("chyba", err.message, err.response.data);
          this.errorMessage = err.response.data.message;
        });
    },



    login2() {

      var formdata={ email: this.email, password: this.password }
      //console.log('Login click', formdata);
      const content = qs.stringify(formdata)

      this.$store.dispatch(AUTH_REQUEST, content).then(() => {
        this.$store.dispatch(USER_REQUEST).then(user => {
          console.log("LOGGED IN:", user, user.locale, this.$i18n.locale)
          if (user.locale && user.locale != this.$i18n.locale) {
            console.log("LOCALE CHANGED BY LOGIN:", user.locale)
            this.$root.$emit('locale-change', user.locale, false)
          }
          /* else if(!user.options.locale){
            axiosApiInstance.post('/api/profile/update-options', {opt: {locale: this.$i18n.locale} }).then(response => {
              console.log('LOCALE SAVED!!', response.data);
              this.$store.dispatch(USER_REQUEST)
            });
          }*/

          //console.log('Login redirect', this.$route.query.from);
          if (this.$route.query.from !== undefined) {
            console.log('Login redirect to FROM');
            this.$router.replace(this.$route.query.from)
          } else {
            //console.log('Login redirect HOME');
            this.$router.push({ path: '/home' })
          }
        }, error => {
          console.log('Login ERROR', error);
          this.setErrorMessage(error)
          this.isLogging = false;
        })
      }, error => {
        console.log('Login ERROR', error);
        this.setErrorMessage(error)
        this.isLogging = false;
      }, error => {
        console.log('Login1 ERROR', error);
        this.setErrorMessage(error)
        this.isLogging = false;
      })
    },

    setErrorMessage: function (key) {
      switch(key){
        case "AllEmpty":
          this.errorMessage = this.$t('Login.je_treba_vyplnit_prihlasovaci_udaje')
          break;
        case "UsernameEmpty":
          this.errorMessage = this.$t('Login.je_treba_vyplnit_prihlasovaci_e-mail')
          break;
        case "PasswordEmpty":
          this.errorMessage = this.$t('Login.je_treba_vyplnit_heslo')
          break;
        case "UsernameNotFound":
          this.errorMessage = this.$t('Login.prihlasovaci_e-mail_nebyl_nalezen')
          break;
        case "PasswordIsWrong":
          this.errorMessage = this.$t('Login.zadane_heslo_je_neplatne')
          break;

        default:
          this.errorMessage = this.$t('Login.vyskytla_se_chyba')
      }
    },






    logout(){
      axios.get(process.env.VUE_APP_API_ROOT_URL + "/logout").then(result => {
          if (result.status == 200) {

            localStorage.setItem('user-token', '')
            axios.defaults.headers.common['Authorization'] = ""

            this.$store.commit('setuser', false)
            this.$router.push('/')
          }
      })


    }
  }
};
</script>

<style>
img {
  max-width: 80%;
}
</style>
