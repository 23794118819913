export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";

export const FRIENDS_REQUEST = "FRIENDS_REQUEST";
export const FRIENDS_SUCCESS = "FRIENDS_SUCCESS";
export const FRIENDS_ERROR = "FRIENDS_ERROR";

export const GROUPS_REQUEST = "GROUPS_REQUEST";
export const GROUPS_SUCCESS = "GROUPS_SUCCESS";
export const GROUPS_ERROR = "GROUPS_ERROR";
