import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home2.vue'

Vue.use(VueRouter)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/PodminkyPouziti.vue')
  },
  {
    path: '/qr-print/:id',
    name: 'qr-print',
    component: () => import(/* webpackChunkName: "about" */ '../views/QrPrint/QrPrint.vue')
  },
    {
    path: '/qr-direct-print',
    name: 'qr-print2',
    component: () => import(/* webpackChunkName: "about" */ '../views/QrPrint/QrDirectPrint.vue')
  },

  {
    path: '/qr-generator',
    name: 'QRgen',
    component: () => import('../views/QRgen.vue')
  },



  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/help',
    name: 'help',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Help.vue')
  },

/*
  {
    path: '/join-account/:regtoken',
    name: 'join-account',
    component: () => import('../views/JoinAccount.vue')
  },
*/
  {
    path: '/join-autologin/:regtoken',
    name: 'join-account-autologin',
    component: () => import('../views/JoinAccountAutologin.vue')
  },
  {
    path: '/join-user-account/:regtoken',
    name: 'join-account-uexist',
    component: () => import('../views/JoinAccountUexist.vue')
  },

  {
    path: '/register',
    name: 'register'
  },

  {
    path: '/admin',
    name: 'Admins',
    component: () => import('../views/admin/Admins.vue'),
    children: [
      {
        path: 'translator',
        name: 'translator',
        component: () => import( '../views/admin/Translate.vue')
      }
      ,{
        path: 'activity-types',
        name: 'activity-types',
        component: () => import( '../views/admin/ActivityTypes.vue')

      },

      {
        path: '/admin/users',
        name: 'Users',
        component: () => import( '../views/admin/Users.vue')
      },
      {
        path: '/admin/companies',
        name: 'Companies',
        component: () => import( '../views/admin/Companies.vue')
      },

      {
        path: '/admin/options',
        name: 'Options',
        component: () => import(  '../views/admin/Options.vue')
      },


      {
        path: '/admin/motor-notices',
        name: 'motor-notices',
        component: () => import(  '../views/admin/MotorNotice.vue')
      },

      // Nastavení
      {
        path: 'interval-settings',
        name: 'interval-settings',
        component: () => import('../views/admin/IntervalSettings.vue')
      },
      {
        path: 'alarms',
        name: 'alarm-settings',
        component: () => import('../views/admin/Alarms.vue')
      },
      {
        path: 'confirmation',
        name: 'confirmation',
        component: () => import('../views/admin/Confirmation.vue')
      },
      {
        path: 'licences',
        name: 'licences',
        component: () => import('../views/admin/Licence.vue')
      }
    ]
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import('../views/Main.vue'),
    children: [
      {
        path: '/home',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue'),
      },


      {
        path: 'non-confirmed',
        name: 'nonconfirmed',
        component: () => import('../views/NonConfirmed.vue'),
      },
      {
        path: 'motors/',
        component: () => import('../components/SeznamMotoru.vue'),
      },
      {
        path: '/community/:logintoken',
        name: 'community',
        component: () => import('../views/Community'),
      },

      {
        path: '/community',
        name: 'community1',
        component: () => import('../views/Community'),
      },
      {
        path: '/profil',
        name:'profil',
        component: () => import('../views/mikroblog/Profil'),
      },

      {
        path: '/notice',
        component: () => import('../views/Notice'),
      },

      {
        path: 'motors/:id',
        component: () => import('../components/KartaMotoru.vue'),
        children: [
           {
            path: '/',
            name: 'motor-deska',
            component: () => import( '../views/motor/Deska.vue')
          },
          {
            path: 'qr',
            name: 'motor-oqr',
            component: () => import( '../views/motor/MotorQr.vue')
          },
            {
            path: 'options',
            name: 'motor-options',
            component: () => import( '../views/motor/MotorOptions.vue')
          },
          {
            path: 'book',
            name: 'motor-book',
            component: () => import( '../views/motor/Kniha.vue')
          }
          ,
          {
            path: 'notifications',
            name: 'motor-notif',
            component: () => import( '../views/motor/Notifications')
          }
          ,
          {
            path: 'chart',
            name: 'motor-chart',
            component: () => import( '../views/motor/Chart')
          }
          ,
          {
            path: 'alarms',
            name: 'motor-alarms',
            component: () => import( '../views/motor/Alarms')
          }
        ]
      },
        // Náš team
      {
        path: '/team/:id',
        name: 'team',
        component: () => import('../views/Team.vue')
      },
      {
        path: '/groups',
        name: 'groups',
        component: () => import('../views/Groups.vue')
      },
      {
        path: '/company',
        name: 'company',
        component: () => import('../views/company/Company.vue')
      },
      {
        path: '/manufacture',
        name: 'manufacture',
        component: () => import('../views/Manufacture.vue')
      },

    ]
  },

  {
    path: '/regc/:key',
    name: 'PotvrzeniRegistrace2',
    component: () => import('../views/PotvrzeniRegistrace.vue')
  },
  {
    path: '/regc',
    name: 'PotvrzeniRegistrace1',
    component: () => import('../views/PotvrzeniRegistrace.vue')
  },

  {
    path: '/resetpass/:token',
    name: 'ResetPassword2',
    component: () => import('../views/ResetPassword2.vue')
  },


  {
    path: '/accept-company-token/:token',
    name: 'AcceptCompany',
    component: () => import('../views/AcceptCompany.vue')
  },

  {
    path: '/accept-new-user/:token',
    name: 'AcceptUserByAdmin',
    component: () => import('../views/accept-new-user.vue')

  },
  {
    path: '/community2/:logintoken',
    name: 'community2',
    component: () => import('../views/Community2'),
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
