import Vue from 'vue'
import VueI18n from 'vue-i18n'
const axios = require('axios')
Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)


  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  axios.get(process.env.VUE_APP_API_ROOT_URL + "/translate"+"?t="+ new Date().getTime()).then(response => {
    for (var i=0 ; i< response.data.length; i++){
      let d = response.data[i];
      if (!messages[d.locale])messages[d.locale]=[];
      messages[d.locale][d.key] = d.value
    }
  });

  return messages
}
//var loc = localStorage.locale || 'en';

export default new VueI18n({
  locale:  localStorage.locale || 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})
