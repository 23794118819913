<template>
  <div style="position: absolute; top:0; right:-5px; width:20px; height:20px;">
    <span class="ukazatel-alertu ukazatel-alertu-toplista" v-if="numer_active_notices>0">{{numer_active_notices}}</span>
    <div v-if="notice_visible"  class="topnotices">
      <div class="h4">{{$t('menu.notice')}}</div>

      <div  class='notification-item d-flex' v-for="n in notifications_gruped" :key="n.id" >

        <div class="img" v-if="n.entity_type=='Motor' ">
          <img :src="n.motor_ico ? n.motor_ico : '/img/motor_ikona.png'"/>
        </div>
        <div v-if="n.entity_type=='Motor'" :class="n.active ? 'active' : 'old'" @click.prevent="goToMotor(n)">
            <div><b>{{ $t('notice.unread-motor')}}: </b>
              {{n.motor_name}} {{n.motor_sn}} <span class="badge badge-warning">{{n.num_unread}}</span></div>
            <div class="datum">{{n.act_date | dateTime}}</div>
        </div>

        <div v-if="n.entity_type=='Comment' && n.active" :class="n.active ? 'active' : 'old'">
          <span :href="n.item_url" @click.prevent="gotoMkroblog(n)" v-html="$t(n.message2)"></span>
           <div class="datum">{{n.created_at | dateTime}}</div>
        </div>


        <div v-if="n.entity_type=='Invitation' && n.active">
              {{$t('m.notice.team-invitation')}} {{n.account_name}}
            <a :href="n.accept_url" @click.prevent="acceptInvitation(n)">{{ $t('m.notice.PříjmoutPozvání') }}</a>
            <div class="datum">{{n.created_at | dateTime}}</div>
        </div>
        <div v-if="(n.entity_type=='MikroblogRating' || n.entity_type=='Mikroblog') && n.active">
            <div v-html="n.message2" @click="gotoMkroblog(n)"></div>
            <div class="datum">{{n.created_at | dateTime}}</div>
        </div>


      <!--
        <div class="img" v-if="n.entity_type=='Motor' ">
          <img :src="n.motor_ico ? n.motor_ico : '/img/motor_ikona.png'"/>
        </div>
        <div v-else class="enve-box">
          <i class="far fa-envelope"></i>
        </div>
        <div>

          <div v-if="n.entity_type=='Motor'" :class="n.active ? 'active' : 'old'" @click.prevent="goToMotor(n)">
            <div><b>{{ $t(n.message_code)}}</b></div>
            <div>{{n.motor_name}} {{n.motor_sn}}</div>
            <div class="datum">{{df(n.act_date)}}</div>
          </div>
          <div v-else>
            <div v-if="n.message" @click.prevent="click_simple_message(n)">
              <div v-html="n.message"></div>
              <div class="datum">{{df(n.created_at)}}</div>
            </div>
          </div>


        </div>
        -->
      </div><!-- cykl -->
    </div>
  </div>
</template>

<script>
const moment = require('moment');
require('moment/locale/cs');
moment.locale('cs');
const axios = require("axios");

import ClickOutside from 'vue-click-outside'

export default {
  name: "Notice",
  directives: {
    ClickOutside
  },

  data(){
    return {
      notice_visible: false,
      notifications:[],
      unread_nums:{}

    }
  },
  filters: {

    date(value) {
      value = moment(value).format('DD. MM. YYYY')
      return value
    },

    dateTime(value) {
      if (value==null) return ""
      value = moment(value).format('DD. MM. YYYY, H:mm')
      return value
    }
  },
  computed:{
    numer_active_notices(){

      let n=0;
      for(let i in this.notifications){
        let z = this.notifications[i]
        if (z.entity_type=='Motor' && !z.motor_sn) continue;


        //console.log('act akt ', this.notifications[i].active)
        if (z.entity_type!='Motor' && !z.message2) continue

        if (this.notifications[i].active==true)  n++
      }
      return n
    },

    notifications_gruped(){
      let arr = []
      let motor_ids=[]
      for(let i in this.notifications) {
        let z = this.notifications[i]
        if (z.entity_type=='Motor'){
          //console.log('act , ', z.id, z.active)
          if (z.active) {
            if (motor_ids.indexOf(z.entity_id) >= 0) {
              for(let j in arr){
                if (arr[j].entity_id == z.entity_id) arr[j].num_unread ++
              }
            } else {
              motor_ids.push(z.entity_id)
              z.num_unread = 1
              arr.push(z)
            }
          }
        }else{
          if (z.active && z.message2) arr.push(z)
        }
      }
      return arr;
    },


    me() {
      return this.$store.getters.me;
    }
  },

  methods:{
    toggle () {
      this.notice_visible = !this.notice_visible

    },

    hide () {

      this.notice_visible = false
    },

    goToMotor(notice){
      if (notice.motor_token){
        this.$router.push(`/main/motors/${notice.motor_token}/notifications`)
      }
    },
    gotoMkroblog(n){
      const urlarr = n.item_url.split('/community')
      console.log('url=', urlarr[1])
      if (urlarr[1]) {
        this.$router.push(`/community${urlarr[1]}`)
      }
      this.$set(n,'active', false)
    },
    acceptInvitation(n){
      //let url2 = '/join'+n.accept_url.split('/join')[1]
      //this.$router.push( url2 )

      axios.get(process.env.VUE_APP_API_ROOT_URL+`/notifier/read?type=Invitation&id=${n.id}` , config).then(response => {
        console.log('/notifier/read' , response.data)
      })

      axios.get(n.accept_url).then(result => {
        if (result.status == 200 || result.status == 202) {
          this.$toasted.show( this.$t('team.table.potvrzeno'),{ fullWidth: true,iconPack: 'fontawesome', type: 'success' ,duration : 3000})
        }
      }).catch(e =>{
        console.error(' notif errrr', e)
      })

       const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }




      this.$set(n,'active', false)

    },

    click_simple_message(notice){
      if (notice.entity_type=='Comment'){
        //console.log('na koment', notice.entity_id)
        this.$router.push(`/community#item-${notice.entity_id}`)
      }
    },
    df: function (time, format) {
      if (!time) return '';
      moment.locale(this.$i18n.locale);
      var a = moment(time);
      return a.format(format ? format : "LLL")
    },
    refreshData(){
      //console.log("louduju  notice")
      //this.notice_visible = visible
      if (this.me==undefined || this.me.id=='') return


      if (this.$store.getters.me.company && this.$store.getters.me.company.status=='rejected'  && this.$route.name!='nonconfirmed'  && this.$route.name!='profil'){
        this.$router.push('/main/non-confirmed')
        return
      }

      axios.get(process.env.VUE_APP_API_ROOT_URL +"/notifications.json?mikroblog=1").then(result => {
        if (result.status == 200 || result.status == 304) {
          this.notifications = result.data
        }
      }).catch(e =>{
        if (e.response.status==401){
          this.$store.commit('setuser', {} )
          if (this.$route.name!='home' && this.$route.name!='PotvrzeniRegistrace2'  ){
           // this.$router.push('/')
          }

        }
      })
    },
    refreshDataifEmpty(){
      if (this.notifications.length<1){
        this.refreshData()
      }
    }
  },
  mounted() {
    console.log("notic mounted")
    this.refreshData()
  }
}
</script>

<style scoped lang="scss">
.topnotices{
  position: absolute;
  right:0px;
  top:30px;

  background: white;
  padding:10px;
  z-index: 100;
  min-width: 300px;
  text-align: left;
  box-shadow: 4px 4px 8px #cccccc60;
}

.notification-item{
  margin: 10px 0;

  .datum{
    color:gray;
    font-size: 80%;
  }

  .old{
    color: #888;
    b{
      font-weight: normal;
    }
  }

}

.ukazatel-alertu-toplista{
  top:-2px;
  background-color: #ffc107c0;
  display: block;
  border-radius:9px;
}

.enve-box{
  width:27px;
  padding: 0 6px;
  text-align: center;
  margin-right:5px


}
</style>
